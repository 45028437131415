import * as Color from 'color'

import Img from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import { colors } from '../variables'
import font from '../fonts'
import { minWidth } from '../breakpoints'

export default ({ item, margin }) => (
  <Link
    to={item.link}
    css={{
      background: 'white',
      boxShadow: `0 10px 50px ${Color(colors.blue60).fade(0.75)}`,
      color: 'black',
      marginBottom: 30,
      textDecoration: 'none',
      transition: 'transform .3s cubic-bezier(0, 0, 0, 1)',
      width: '100%',
      [minWidth.medium]: {
        margin: `0 ${margin}px ${margin}px 0`,
        width: `calc(${100 / 2}% - ${margin}px)`,
      },
      [minWidth.laptop]: {
        width: `calc(${100 / 3}% - ${margin}px)`,
      },
      '&:hover': {
        transform: 'scale(1.035)',
      },
    }}
  >
    {item.fields.image && item.fields.image.localFile && (
      <Img fluid={item.fields.image.localFile.childImageSharp.fluid} />
    )}
    <div
      css={{
        padding: 20,
        [minWidth.medium]: {
          padding: '25px 30px',
        },
      }}
    >
      <h4
        css={{
          fontSize: '1.125rem',
          lineHeight: '1.5em',
          marginBottom: 0,
          [minWidth.medium]: {
            marginBottom: 5,
          },
        }}
      >
        {item.fields.title}
      </h4>
      <div
        css={{
          color: colors.blue60,
          fontFamily: font('Raleway'),
          fontSize: '0.9rem',
          fontWeight: 800,
        }}
      >
        {item.date}
      </div>
    </div>
  </Link>
)

import { StaticQuery, graphql } from 'gatsby'

import React from 'react'
import { css } from '@emotion/core'
import { minWidth } from '../breakpoints'

export default ({ id, children, large = false }) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "blog.jpg" }) {
          childImageSharp {
            fixed(width: 1600, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={({ image }) => (
      <div
        id={id}
        css={css`
          background: url('${image.childImageSharp.fixed.src}') no-repeat;
          background-position: bottom -1px center;
          background-size: 100%;
          padding-bottom: 33%;

          ${minWidth.tablet} {
            padding-bottom: 25%;
          }

          ${minWidth.full} {
            padding-bottom: ${large ? '400px' : '350px'};
          }

          @media(min-width: 1921px) {
            padding-bottom: 450px;
          }

        `}
      >
        {children}
      </div>
    )}
  />
)
